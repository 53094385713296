<template>
    <base-section id="theme-features">
    <base-section-heading title="Lista de Soporte">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col>
            <v-data-table
            :headers="headers"
            :items="support"
            :loading="loading"
            class="elevation-1"
            >
            <template v-slot:item.created="{ item }">
                <v-list-item>
                 {{getDateString(item.created)}}
                </v-list-item>
            </template>
            <template v-slot:item.responded="{ item }">
                <v-list-item>
                    <v-switch label="" v-model="item.responded" @change="saveSupport(item)" />
                </v-list-item>
            </template>
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    </base-section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    metaInfo: { title: 'Users' },
    data: () => ({
        totalUsers: 0,
        support: [],
        loading: true,
        options: {},
        headers: [
          {
            text: 'Id',
            align: 'start',
            value: 'supportId',
          },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Subject', value: 'subject' },
          { text: 'Description', value: 'description' },
          { text: 'Date', value: 'created' },
          { text: 'IP', value: 'ipAddress', align: 'center', },
          { text: 'Responded', value: 'responded', align: 'center', },
        ],
    }),
    mounted(){
        this.loadSupport();
    },
    methods:{
        ...mapActions(['getSupport','updateSupport','showSnackbar']),
        loadSupport(){
            this.getSupport().then(res => {
                this.support = res.data.data;
                this.totalUsers = res.data.totalRecords;
                this.loading = false;
                //console.log(this.users);
            })
        },
        saveSupport(item){
            this.updateSupport(item).then(res => {
                if(res.status == 200){
                    this.showSnackbar({
                        showing:true,
                        message: `Support has been updated id: ${res.data.data.supportId} - Name: ${res.data.data.name}`,
                        color:'success'
                    });
                }
                //console.log(res);
            }).catch((err)=>{
                  console.log(err);
              });
        },
        getDateString(date){
            if(date == undefined)
                return "";
            var dformat = this.$func.getFullDateString(date);

            return dformat;
        },
    }
}
</script>

<style>

</style>